<template>
  <div class="checklist">
    <b-dropdown :open="false" :disabled="disabled" position="is-bottom-left">
      <button slot="trigger" slot-scope="props" class="button">
        <span v-if="!value">
          0 {{ i18n.selected }}
        </span>
        <span v-else>
          {{ value.length }} {{ i18n.selected }}
        </span>
        <span>
          <i :class="props.open ? 'mdi mdi-menu-up' : 'mdi mdi-menu-down'" />
        </span>
      </button>
      <div class="card-content static">
        <div class="content">
          <div
            v-for="v in choices"
            :key="v.value"
          >
            <div
              v-if="typeof v === 'object'"
              :class="value && value.indexOf(v.value) !== -1 ? 'item selected': 'item'"
              @click="toggleValue(v)"
            >
              <i v-if="value && value.indexOf(v.value) !== -1" class="mdi mdi-check" />
              <b>
                {{ v.value }}
              </b>
              <div class="description">
                {{ v.description }}
              </div>
            </div>
            <div
              v-if="typeof v === 'string'"
              :class="value && value.indexOf(v) !== -1 ? 'item selected': 'item'"
              @click="toggleValue({ value: v })"
            >
              <i v-if="value && value.indexOf(v) !== -1" class="mdi mdi-check" />
              <b>
                {{ v }}
              </b>
            </div>
          </div>
          <div
            v-for="v in unlistedValues"
            :key="v"
            class="item selected': 'item"
            @click="toggleValue({ value: v })"
          >
            <i class="mdi mdi-check" />
            <b>
              {{ v }}
            </b>
            <div class="description">
              {{ i18n.not_included_in_proposed_values }}
            </div>
          </div>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import i18n from 'i18n/components/ui/Checklist.json';

export default {
  name: 'Checklist',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    choices: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { i18n };
  },
  computed: {
    unlistedValues() {
      const res = [];
      const valueKeys = this.choices.map((v) => (typeof v === 'object' ? v.value : v));
      if (this.value) {
        for (let i = 0; i < this.value.length; i++) {
          if (!valueKeys.includes(this.value[i])) {
            res.push(this.value[i]);
          }
        }
      }
      return res;
    },
  },
  methods: {
    toggleValue(v) {
      console.log('CL base toggleValue', v);
      let { value } = this;
      if (!value) {
        value = [];
      }
      if (!value.includes(v.value)) {
        value.push(v.value);
      } else {
        value.splice(this.value.indexOf(v.value), 1);
      }
      this.$emit('input', value);
    },
  },
};
</script>
<style scoped>
.card {
  width: 100%;
}
.card-header-title, .card-header-icon {
  margin: 0 !important;
  padding: 6px;
  white-space: nowrap;
}
.card-header-title {
  padding-left: 12px;
  cursor: pointer;
}
.card-content {
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}
.item {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  padding-left: 36px;
  padding-right: 12px;
  min-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.item i {
  position: absolute;
  left: 6px;
  top: 4px;
  font-size: 140%;
}
.item.selected {
  background: #dedede;
}
.item:hover {
  background: #f0f0f0;
}
.description {
  font-size: 75%;
}
.checklist >>> .dropdown-trigger, .checklist .dropdown-trigger button,.checklist >>> .dropdown {
  width: 100%;
}
.checklist .dropdown-trigger button {
  text-align: left;
}
.checklist .dropdown-trigger button .mdi {
  float:right;
}
.checklist >>> .dropdown-menu {
}
</style>
