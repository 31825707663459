<template>
  <b-dropdown ref="dropdown" aria-role="list" class="icon-selector" :position="position" append-to-body>
    <button slot="trigger" :class="`button full-width ${buttonClass}`">
      <span v-if="value"><i :class="`mdi mdi-${value}`" /></span>
      <span v-else>{{ i18n.nothing }}</span>
      <i class="mdi mdi-menu-down" />
    </button>
    <span class="icon-selector-content">
      <div class="search-input control has-icons-left has-icons-right">
        <input class="input" v-model="searchTerm" placeholder="Chercher une icône" />
        <span class="icon is-small is-left">
          <i class="mdi mdi-magnify" />
        </span>
      </div>
      <span class="results">
        <a
          v-for="i in filteredIcons"
          :key="i"
          aria-role="listitem"
          @click="select(i)"
        >
          <i :class="`mdi mdi-${i} mdi-24px`" />
        </a>
      </span>
    </span>
  </b-dropdown>
</template>
<script>
import icons from '@/core/icons';
import i18n from 'i18n/components/ui/IconSelector.json';
import Fuse from 'fuse.js';
import { debounce } from 'vue-debounce';

export default {
  name: 'IconSelector',
  props: {
    value: {
      type: String,
      default: undefined,
    },
    position: {
      type: String,
      default: undefined,
    },
    buttonClass: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      i18n,
      icons,
      filteredIcons: icons,
      searchTerm: '',
      fuse: new Fuse(icons.map((i) => ({ label: i })), {
        keys: ['label'],
        threshold: 0.1,
        limit: 50,
      }),
      debouncedSearch: debounce(() => {
        if (!this.searchTerm || this.searchTerm === '') {
          this.filteredIcons = icons;
        } else {
          this.filteredIcons = this.fuse.search(this.searchTerm).map((i) => i.label);
        }
      }, 500),
    };
  },
  watch: {
    searchTerm(q) {
      this.debouncedSearch();
    },
  },
  methods: {
    select(v) {
      this.$refs.dropdown.toggle();
      this.$emit('input', v);
    },
  },
};
</script>
<style scoped>
a {
  padding: 8px;
  display: inline-block;
}
.icon-selector >>> i:before {
  font-size: 150%;
}
</style>
<style>
.icon-selector .dropdown-content {
  max-height: 300px;
  overflow: hidden;
}
.icon-selector .dropdown-menu {
  min-width: 400px;
}

.icon-selector .icon-selector-content {
  position: relative;
  display: block;
}

.icon-selector .icon-selector-content .results {
  margin-top: 30px;
  height: 260px;
  display: block;
  overflow-y: auto;
}
.icon-selector .search-input {
  position: absolute;
  top: -38px;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
